// Tool2.js
import React from 'react';

const OBSTemplate = () => {
    return (
        <div>
            <h2>OBS Template</h2>
            <p>Description of OBST emplate</p>
            <div>
                {/* Tool 2 content */}
            </div>
        </div>
    );
}

export default OBSTemplate;
