// App.js
import React from 'react';
import Header from './Components/Header';
import Tools from './Components/Tools';
import Footer from './Components/Footer';

const App = () => {
    return (
        <div>
            <Header />
            <Tools />
            <Footer />
        </div>
    );
}

export default App;
