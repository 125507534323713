// Header.js
import React from 'react';

const Footer = () => {
    return (
        <div>
            Created by Kadu Waengertner - For suggestions, bug reports and feature requests send me a message on Twitter.
        </div>
    );
}

export default Footer;
