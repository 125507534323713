// Tools.js
import React from 'react';
import OBSTemplate from '../Tools/OBSTemplate';

const Tools = () => {
    return (
        <div>
            <OBSTemplate />
        </div>
    );
}

export default Tools;